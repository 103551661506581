<template>
  <v-card class="mb-6 px-3 mt-3">
    <!-- <v-row>
      <v-col cols="12" sm="6" md="4">
        <v-text-field
          label="ФИО"
          required
          v-model="user.title"
          :rules="titleRules"
          @change="updateUser({ title })"
        ></v-text-field>
      </v-col>
       <v-col
                cols="12"
                sm="6"
                md="4"
            >
                <v-text-field
                label="Имя*"
                required
                ></v-text-field>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                md="4"
            >
                <v-text-field
                label="Отчество"
                persistent-hint
                required
                ></v-text-field>
            </v-col>
       <v-col cols="3">
                <v-text-field
                label="Телефон*"
                required
                maxlength="11"
                placeholder="7xxxxxxxxxx"
                ></v-text-field>
            </v-col> 
      <v-col cols="12" sm="6" md="4">
        <v-menu
          ref="birthdayDateMenu"
          v-model="birthdayDateMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="birthdayDateAtText"
              label="Дата рождения*"
              prepend-icon="mdi-calendar-month"
              readonly
              v-bind="attrs"
              v-on="on"
              outlined
            ></v-text-field>
          </template>
          <v-date-picker
            :max="maxDateTo('birthdayDate')"
            scrollable
            first-day-of-week="1"
            locale="ru-Latn"
            no-title
            v-if="birthdayDateMenu"
            v-model="user.birthday"
            :active-picker.sync="activePickerTwo"
            full-width
            @change="saveBirthdayDate"
          ></v-date-picker>
        </v-menu>
      </v-col>
       <v-col
                cols="4"
            >
                <v-autocomplete
                v-model="regionId"
                :items="allRegions"
                item-text="name"
                item-value="id"
                no-data-text="Совпадений не найдено"
                label="Регион по прописке*"
                required
                outlined
                ></v-autocomplete>
            </v-col> 
      <v-col cols="12" sm="6" md="4">
        <v-text-field
          label="Паспорт серия и номер"
          persistent-hint
          required
          outlined
          prepend-icon="mdi-passport"
          v-model="user.passport"
          @change="updateUser({ passport })"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-menu
          ref="passportDateMenu"
          v-model="passportDateMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="passportDateAtText"
              label="Когда выдан"
              prepend-icon="mdi-calendar-month"
              readonly
              v-bind="attrs"
              v-on="on"
              outlined
            ></v-text-field>
          </template>
          <v-date-picker
            :max="maxDateTo('passportDate')"
            first-day-of-week="1"
            locale="ru-Latn"
            no-title
            v-if="passportDateMenu"
            v-model="user.passportDate"
            :active-picker.sync="activePicker"
            full-width
            @change="savePassportDate"
          ></v-date-picker>
        </v-menu>
      </v-col>
       <v-col
                cols="12"
            >
                <v-text-field
                v-model="user.passportIssued"
                label="Кем выдан"
                persistent-hint
                required
                @change="updateUser({passportIssued})"
                ></v-text-field>
            </v-col> 
       <v-col
                cols="12"
            >
                <v-text-field
                label="Адрес фактического проживания"
                required
                >{{user.actualAdress}}</v-text-field>
            </v-col> 
    </v-row> -->

    <v-card-text>
      <h2>Прикрепите документы</h2>
      <p>Разрешены файлы изображений</p>
    </v-card-text>
    <v-container>
      <v-row>
        <v-col cols="12" sm="6" md="6">
          <v-row align="center" justify="center">
            <v-card
              justify="center"
              outlined
              class="mb-3"
              align="center"
              width="180"
              height="200"
              elevation="0"
              @click="launchFilePicker('filePassportFront')"
            >
              <v-container>
                <v-btn
                  elevation="12"
                  icon
                  style="
                      position: absolute;
                      z-index: 3;
                      left: 67.5px;
                      top: 70px;
                    "
                  large
                  aria-label="upload passport front"
                  ><v-icon v-if="isFileEmpty('passportFront')"
                    >mdi-camera-plus</v-icon
                  >
                  <v-icon v-if="!isFileEmpty('passportFront')"
                    >mdi-checkbox-marked-circle</v-icon
                  >
                </v-btn>

                <v-img
                  v-if="userFiles.passportFront.link"
                  :src="userFiles.passportFront.link"
                  height="150"
                  max-width="150"
                ></v-img>
                <v-img
                  v-if="passportFront"
                  :src="passportFront"
                  height="150"
                  max-width="150"
                ></v-img>
                <v-img
                  v-if="isFileEmpty('passportFront')"
                  src="https://a-prokat.storage.yandexcloud.net/1/6747ee36-29a2-472f-9be8-4ec57be5839b/%D0%BF%D0%B0%D1%81%D0%BF%D0%BE%D1%80%D1%82%20%D1%81%D1%82%D1%801_%D1%87-%D0%B1.png"
                  height="150"
                  max-width="150"
                  class="mb- default-img"
                ></v-img>
                <input
                  type="file"
                  ref="filePassportFront"
                  label="Паспорт (главная)"
                  prepend-icon="mdi-camera"
                  truncate-length="25"
                  accept="image/*"
                  :rules="filesRules"
                  show-size
                  counter
                  outlined
                  style="display: none"
                  @change="onFileChange('passportFront', $event.target.files)"
                />

                <v-card-text class="text--secondary py-1 px-0"
                  >Паспорт (главная)</v-card-text
                >
              </v-container>
            </v-card>
          </v-row>
        </v-col>

        <v-col cols="12" sm="6" md="6">
          <v-row align="center" justify="center">
            <v-card
              justify="center"
              outlined
              class="mb-3"
              align="center"
              height="200"
              width="180"
              elevation="0"
              @click="launchFilePicker('filePassportBack')"
            >
              <v-container>
                <v-btn
                  elevation="12"
                  icon
                  style="
                      position: absolute;
                      z-index: 3;
                      left: 67.5px;
                      top: 70px;
                    "
                  large
                  aria-label="upload passport back"
                  ><v-icon v-if="isFileEmpty('passportBack')"
                    >mdi-camera-plus</v-icon
                  >
                  <v-icon v-if="!isFileEmpty('passportBack')"
                    >mdi-checkbox-marked-circle</v-icon
                  >
                </v-btn>
                <v-img
                  v-if="userFiles.passportBack.link"
                  :src="userFiles.passportBack.link"
                  height="150"
                  max-width="150"
                ></v-img>
                <v-img
                  v-if="passportBack"
                  :src="passportBack"
                  height="150"
                  max-width="150"
                ></v-img>
                <v-img
                  v-if="isFileEmpty('passportBack')"
                  src="https://a-prokat.storage.yandexcloud.net/1/1a6ec00e-0d4d-44c7-b896-7c2755a1f6df/%D0%BF%D0%B0%D1%81%D0%BF%D0%BE%D1%80%D1%82%20%D0%BF%D1%80%D0%BE%D0%BF%D0%B8%D1%81%D0%BA%D0%B0_%D1%87%D0%B1.png"
                  height="150"
                  max-width="150"
                  class="mb-"
                ></v-img>
                <input
                  type="file"
                  ref="filePassportBack"
                  label="Паспорт (прописка)"
                  prepend-icon="mdi-camera"
                  truncate-length="25"
                  accept="image/*"
                  :rules="filesRules"
                  show-size
                  counter
                  outlined
                  style="display: none"
                  @change="onFileChange('passportBack', $event.target.files)"
                />

                <v-card-text class="text--secondary py-1 px-0"
                  >Паспорт (прописка)</v-card-text
                >
              </v-container>
            </v-card>
          </v-row>
        </v-col>

        <v-col cols="12" sm="6" md="6" v-if="showDriver">
          <v-row align="center" justify="center">
            <v-card
              justify="center"
              outlined
              class="mb-3"
              align="center"
              height="210"
              width="180"
              elevation="0"
              @click="launchFilePicker('fileDriveLicenseFront')"
            >
              <v-container>
                <v-btn
                  elevation="12"
                  icon
                  style="
                      position: absolute;
                      z-index: 3;
                      left: 67.5px;
                      top: 70px;
                    "
                  large
                  aria-label="upload driver license front"
                  ><v-icon v-if="isFileEmpty('driveLicenseFront')"
                    >mdi-camera-plus</v-icon
                  >
                  <v-icon v-if="!isFileEmpty('driveLicenseFront')"
                    >mdi-checkbox-marked-circle</v-icon
                  >
                </v-btn>
                <v-img
                  v-if="userFiles.driveLicenseFront.link"
                  :src="userFiles.driveLicenseFront.link"
                  height="150"
                  max-width="150"
                ></v-img>
                <v-img
                  v-if="driveLicenseFront"
                  :src="driveLicenseFront"
                  height="150"
                  max-width="150"
                  class="mb-"
                ></v-img>
                <v-img
                  v-if="isFileEmpty('driveLicenseFront')"
                  src="https://a-prokat.storage.yandexcloud.net/1/4ce46ed0-7089-4387-877a-f18c15f54c51/%D0%B2%D0%BE%D0%B4%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D1%81%D0%BA%D0%BE%D0%B5%20%D1%83%D0%B4%D0%BE%D1%81%D1%82%D0%BE%D0%B2%D0%B5%D1%80%D0%B5%D0%BD%D0%B8%D0%B5%20%D1%87%D0%B1.png"
                  height="150"
                  max-width="150"
                  contain
                ></v-img>
                <input
                  type="file"
                  ref="fileDriveLicenseFront"
                  label="Паспорт (прописка)"
                  prepend-icon="mdi-camera"
                  truncate-length="25"
                  accept="image/*"
                  :rules="filesRules"
                  show-size
                  counter
                  outlined
                  style="display: none"
                  @change="
                    onFileChange('driveLicenseFront', $event.target.files)
                  "
                />

                <v-card-text class="text--secondary py-1 px-0"
                  >Водительское (главная)</v-card-text
                >
              </v-container>
            </v-card>
          </v-row>
        </v-col>

        <v-col cols="12" sm="6" md="6" v-if="showDriver">
          <v-row align="center" justify="center">
            <v-card
              justify="center"
              outlined
              class="mb-3"
              align="center"
              height="210"
              width="180"
              elevation="0"
              @click="launchFilePicker('fileDriveLicenseBack')"
            >
              <v-container>
                <v-btn
                  elevation="12"
                  icon
                  style="
                      position: absolute;
                      z-index: 3;
                      left: 67.5px;
                      top: 70px;
                    "
                  large
                  aria-label="upload passport back"
                  ><v-icon v-if="isFileEmpty('driveLicenseBack')"
                    >mdi-camera-plus</v-icon
                  >
                  <v-icon v-if="!isFileEmpty('driveLicenseBack')"
                    >mdi-checkbox-marked-circle</v-icon
                  >
                </v-btn>
                <v-img
                  v-if="userFiles.driveLicenseBack.link"
                  :src="userFiles.driveLicenseBack.link"
                  height="150"
                  max-width="150"
                ></v-img>
                <v-img
                  v-if="driveLicenseBack"
                  :src="driveLicenseBack"
                  height="150"
                  max-width="150"
                ></v-img>
                <v-img
                  v-if="isFileEmpty('driveLicenseBack')"
                  src="https://a-prokat.storage.yandexcloud.net/1/ab2b9388-516a-4efb-9e60-93f95b956bd6/%D0%B2%D0%BE%D0%B4%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D1%81%D0%BA%D0%BE%D0%B5%20%D0%BE%D0%B1%D0%BE%D1%80%D0%BE%D1%82%D0%BD%D0%B0%D1%8F%20%D1%81%D1%82%D0%BE%D1%80%D0%BE%D0%BD%D0%B0_%D1%87%D0%B1.png"
                  height="150"
                  max-width="150"
                  contain
                ></v-img>
                <input
                  type="file"
                  ref="fileDriveLicenseBack"
                  label="Водительское (обратная)"
                  prepend-icon="mdi-camera"
                  truncate-length="25"
                  accept="image/*"
                  :rules="filesRules"
                  show-size
                  counter
                  outlined
                  style="display: none"
                  @change="
                    onFileChange('driveLicenseBack', $event.target.files)
                  "
                />

                <v-card-text class="text--secondary py-1 px-0"
                  >Водительское (обратная)</v-card-text
                >
              </v-container>
            </v-card>
          </v-row>
        </v-col>
      </v-row>
      <v-alert
        transition="scale-transition"
        :value="!!errorWithFiles"
        type="error"
        class="mt-3"
        >{{ errorWithFiles }}</v-alert
      >
    </v-container>
    <v-row class="mx-auto"
      ><v-checkbox v-model="agree" :rules="agreeRules">
        <template v-slot:label>
          <div>
            Согласен с
            <a
              target="_blank"
              href="https://a-prokat.com/confidential"
              @click.stop
            >
              политикой конфиденциальности
            </a>
          </div>
        </template>
      </v-checkbox></v-row
    >
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import moment from 'moment';
// import _ from "lodash";
export default {
  props: {
    showDriver: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      // regionId: null,
      birthdayDateMenu: false,
      birthdayDate: null,
      passportDateMenu: false,
      passportDate: null,
      name: null,
      passport: null,
      passportIssued: null,
      imageURL: null,
      passportFront: null,
      passportBack: null,
      driveLicenseFront: null,
      driveLicenseBack: null,
      errorWithFiles: null,
      activePicker: null,
      activePickerTwo: null,
      agree: null,
      titleRules: [
        (v) => !!v || 'Обязательное для заполнения',
        (v) => /^[ЁёА-я\s]*$/.test(v) || 'Введите Ваше ФИО на русском',
        (v) =>
          v.split(' ').filter((x) => x != '').length >= 2 ||
          'Введите полностью Фамилию Имя Отчество',
      ],
      agreeRules: [(v) => !!v || 'Обязательное условие'],
      filesRules: [
        (value) =>
          !value ||
          value.size < 50 * 1024 * 1024 ||
          'Файл слишком большой! Максимум 50мб',
      ],
    };
  },
  computed: {
    ...mapGetters(['user', 'allRegions', 'userFiles']),
    birthdayDateAtText() {
      if (this.user.birthday)
        return moment(this.user.birthday).format('DD.MM.YYYY');
      else return '';
    },
    passportDateAtText() {
      if (this.user.passportDate)
        return moment(this.user.passportDate).format('DD.MM.YYYY');
      else return '';
    },
  },
  watch: {
    passportDateMenu(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'));
    },
    birthdayDateMenu(val) {
      val && setTimeout(() => (this.activePickerTwo = 'YEAR'));
    },
    agree(val) {
      this.updateAgreement(val);
    },
  },
  methods: {
    savePassportDate(date) {
      this.$refs.passportDateMenu.save(date);
    },
    saveBirthdayDate(date) {
      this.$refs.birthdayDateMenu.save(date);
    },
    onFileChange(name, file) {
      this.errorWithFiles = null;
      let imageFile = file[0];
      let flag = false;
      for (let userFile in this.user.files) {
        if (
          this.user.files[userFile] &&
          this.user.files[userFile].size === imageFile.size
        ) {
          flag = true;
        }
      }
      if (flag) {
        this.errorWithFiles = 'Такое изображение уже загружено.';
        return;
      }
      if (file.length > 0) {
        if (!imageFile.type.match('image.*')) {
          // check whether the upload is an image
          this.errorWithFiles = 'Загрузите файл изображения.';
        } else if (imageFile.size > 50 * 1024 * 1024) {
          this.errorWithFiles = 'Максимальный разрешенный размер - 50мб';
        } else {
          this[name] = URL.createObjectURL(imageFile);
          if (name === 'passportFront') this.passportFrontChanged(imageFile);
          if (name === 'passportBack') this.passportBackhanged(imageFile);
          if (name === 'driveLicenseFront')
            this.driveLicenseFrontChanged(imageFile);
          if (name === 'driveLicenseBack')
            this.driveLicenseBackhanged(imageFile);
          this.updateErrorState(false);
        }
      }

      // this.isFileEmpty(name)
    },
    isFileEmpty(name) {
      if (this.user.files[name].size || this.user.files[name].link)
        return false;
      return true;
    },
    launchFilePicker(name) {
      this.$refs[name].click();
    },
    maxDateTo(type) {
      if (type == 'birthdayDate')
        return moment()
          .subtract(18, 'years')
          .format('YYYY-MM-DD');
      if (type == 'passportDate') return moment().format('YYYY-MM-DD');
    },
    ...mapActions(['getRegions']),
    ...mapMutations([
      'driveLicenseBackhanged',
      'driveLicenseFrontChanged',
      'passportBackhanged',
      'passportFrontChanged',
      'updateErrorState',
      'updateAgreement',
    ]),
  },
  // watch: {
  //     onFileChange() {
  //         this.isFileEmpty()
  //     }
  // },
  mutations: {
    ...mapMutations(['updateUser']),
  },
  // mounted() {
  // this.getRegions()
  // }
};
</script>

<style lang="scss" scoped>
.default-img {
  filter: grayscale;
}
</style>
